<template>
  <div>
    <loading
      :active.sync="isLoading"
      background-color="#332abb"
      :opacity="0.99"
      color="#fff"
      :is-full-page="fullPage"
    ></loading>

    <ModalBagisYap ref="modalBagisYap"></ModalBagisYap>
    <ModalKampanyaOlustur ref="modalKampanyaOlustur"></ModalKampanyaOlustur>
    <ModalHediyeKarti ref="modalHediyeKarti"></ModalHediyeKarti>
    <modalGallery ref="modalGallery"></modalGallery>
    <MainBanner
      Title="Güvenilirlik"
      Desc="Bilim Seferberliği bir <a href='https://yga.org.tr/' target='_blank'><u>YGA</u></a> projesidir. YGA 2000 yılında Türkiye’de kurulan ve kar amacı gütmeyen uluslararası bir sivil toplum kuruluşudur. YGA düzenli olarak bağımsız kuruluşlarca denetlenir, denetim raporları yıllık olarak yayınlanır."
      ButtonText="Bağış Yap"
      OverlapImage="/svg/banner-bilim-seferberligi.svg"
      TextPosition="between"
      Height="middle"
    />
    <div class="main-continer">
      <section class="section-padding container">
        <ToplananBagisInfographic
          :countTeacher="this.infographic.teacher"
          :countSchool="this.infographic.school"
          :countStudent="this.infographic.student"
        />
      </section>
      <section
        id="neden-bilim-seferberligi"
        class="parallax-section gray-section section-padding section-high mt-3"
      >
        <div
          class="
            container
            d-flex
            flex-column-reverse flex-lg-row
            align-items-center
            justify-content-between
          "
        >
          <div class="text-center text-md-left col-md-5 ml-0">
            <div>
              <h1 class="mb-3">MEB <br />Protokolü</h1>

              <p class="mb-5 mb-md-0">
                MEB bakanlığının katkılarıyla büyüyen Bilim
                Seferberliği projesi kapsamında; Türkiye’nin her köşesindeki
                ihtiyaç sahibi köy okullarına en son teknolojiyi anlatan Bilim
                Setleri gönderilmektedir. Bu sayede çocuklar, bilimi eğlenerek
                öğrenir ve bilimsel bakış açısı kazanırlar. Milli Eğitim
                Bakanlığı ile yapılan protokolü
                <a
                  href="https://bilimseferberligi.org/yga_meb_2021_bilim_seferberligi_protokolu.pdf"
                  target="_blank"
                  ><u>buradan</u></a
                >
                inceleyebilirsiniz.
              </p>

              <p>
                <a
                  class="mt-3 d-block"
                  href="https://meb.gov.tr/"
                  target="_blank"
                  ><img src="/svg/meb-logo.svg"
                /></a>
              </p>
            </div>
            <img src="/img/untitled-1.png" class="d-block d-md-none" />
          </div>
          <div
            class="
              parallax-img-wrapper
              col-md-6
              d-none d-md-flex
              align-items-center
              justify-content-center
            "
          >
            <img src="/img/parallax/02/okul-bagis.svg" class="prl-0" /><img
              src="/img/parallax/02/1.png"
              class="down-1 prl-1"
            /><img src="/img/parallax/02/2.png" class="down-2 prl-2" /><img
              src="/img/parallax/02/3.png"
              class="up prl-3"
            />
          </div>
        </div>
      </section>
      <section class="section-padding">
        <div class="floating-carousel-info text-center">
          <h4>Etki Raporları</h4>
        </div>
        <div class="d-flex flex-wrap justify-content-start container">
          <div class="col-md-6">
            <a
              href="https://bilimseferberligi.org/BS_Etki_Raporu2023.pdf"
              target="_blank"
              class="
                w-100
                single-download
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <p><b>2023</b> Etki Analiz Raporu</p>
              <span class="svg-icon icons-download"></span>
            </a>
          </div>
          <div class="col-md-6">
            <a
              href="https://bilimseferberligi.org/BS_Etki_Raporu_2021.pdf"
              target="_blank"
              class="
                w-100
                single-download
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <p><b>2021</b> Etki Analiz Raporu</p>
              <span class="svg-icon icons-download"></span>
            </a>
          </div>
          <div class="col-md-6">
            <a
              href="https://bilimseferberligi.org/BS_Etki_Raporu_2020.pdf"
              target="_blank"
              class="
                w-100
                single-download
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <p><b>2020</b> Etki Analiz Raporu</p>
              <span class="svg-icon icons-download"></span>
            </a>
          </div>
          <div class="col-md-6">
            <a
              href="https://bilimseferberligi.org/BS_Etki_Raporu_2019.pdf"
              target="_blank"
              class="
                w-100
                single-download
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <p><b>2019</b> Etki Analiz Raporu</p>
              <span class="svg-icon icons-download"></span>
            </a>
          </div>
        </div>
      </section>
      <section class="section-padding">
        <div class="floating-carousel-info text-center">
          <h4>Denetim Raporları</h4>
          <p>
            YGA her yıl düzenli olarak bağımsız kuruluşlarca denetlenir. YGA’nın
            denetim raporlarına aşağıdan ulaşabilirsiniz.
          </p>
        </div>
        <div class="d-flex flex-wrap justify-content-start container">
          <div class="col-md-6">
            <a
              target="_blank"
              href="https://yga.org.tr/cms/storage/upload/finance/KPMG_Bagimsiz_Denetim_Raporu_2020.pdf"
              class="
                w-100
                single-download
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <p><b>2020</b> Bağımsız Denetim Raporu</p>
              <span class="svg-icon icons-download"></span>
            </a>
          </div>
          <div class="col-md-6">
            <a
              target="_blank"
              href="https://yga.org.tr/cms/storage/upload/finance/2019-hayal-ortaklari-dernegi-y%C4%B1ll%C4%B1k-denetim-raporu.pdf"
              class="
                w-100
                single-download
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <p><b>2019</b> Bağımsız Denetim Raporu</p>
              <span class="svg-icon icons-download"></span>
            </a>
          </div>
          <div class="col-md-6">
            <a
              target="_blank"
              href="https://yga.org.tr/cms/storage/upload/finance/HO_Rapor_2018_TR.PDF"
              class="
                w-100
                single-download
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <p><b>2018</b> Bağımsız Denetim Raporu</p>
              <span class="svg-icon icons-download"></span>
            </a>
          </div>
          <div class="col-md-6">
            <a
              target="_blank"
              href="https://yga.org.tr/cms/storage/upload/finance/HO_iktisadi.pdf"
              class="
                w-100
                single-download
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <p><b>2017</b> Bağımsız Denetim Raporu</p>
              <span class="svg-icon icons-download"></span>
            </a>
          </div>
          <div class="col-md-6">
            <a
              target="_blank"
              href="https://yga.org.tr/cms/storage/upload/finance/hayal_ortaklari_dernegi_2016_rapor.pdf"
              class="
                w-100
                single-download
                d-flex
                justify-content-between
                align-items-center
              "
            >
              <p><b>2016</b> Bağımsız Denetim Raporu</p>
              <span class="svg-icon icons-download"></span>
            </a>
          </div>
        </div>
      </section>
      <div class="spacer clearfix"></div>
    </div>
    <FooterLanding />
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
var VueScrollTo = require("vue-scrollto");
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import {
  Swiper,
  Scrollbar,
  EffectCoverflow,
  SwiperClass,
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
  EffectFade,
} from "swiper";
Swiper.use([
  Navigation,
  Pagination,
  Scrollbar,
  EffectCoverflow,
  Mousewheel,
  Autoplay,
  EffectFade,
]);
import "swiper/swiper-bundle.css";

import ButtonRounded from "@/components/ButtonRounded";
import MainBanner from "@/components/MainBanner";
import FooterLanding from "@/components/FooterLanding";
import ModalBagisYap from "@/components/ModalBagisYap";
import ModalKampanyaOlustur from "@/components/ModalKampanyaOlustur";
import ModalHediyeKarti from "@/components/ModalHediyeKarti";
import BagisSec from "@/components/BagisSec";
import FloatingCard from "@/components/FloatingCard";
import ToplananBagisInfographic from "@/components/ToplananBagisInfographic";
import MultipleCarousel from "@/components/MultipleCarousel";
import ModalGallery from "@/components/ModalGallery";
export default {
  name: "Reliability",
  components: {
    ButtonRounded,
    MainBanner,
    FooterLanding,
    ModalBagisYap,
    Loading,
    BagisSec,
    FloatingCard,
    ToplananBagisInfographic,
    ModalKampanyaOlustur,
    ModalHediyeKarti,
    MultipleCarousel,
    ModalGallery,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      infographic: "",
    };
  },
  mounted() {
    this.getInfographic();
  },
  methods: {
    getInfographic() {
      this.$api.getInfographic().then((response) => {
        this.infographic = response;
      });
    },
  },
};
</script>
<style lang="scss">
.single-download {
  padding: 1rem 0px;
  border-top: 1px solid #efefef;
  p {
    font-size: 1.2rem;
    b {
      font-weight: 600;
    }
  }
  span {
    background: var(--purpleDark);
    width: 20px;
    height: 20px;
  }
}
</style>